import React, {Component} from 'react';
import {dataActions} from "../actions/dataActions";
import {connect} from "react-redux";
import Section from "./Section";
import WidalSection from "./WidalSection";
import PatientInfo from "./PatientInfo";
import getBsDateByAdDate from "../helper/nepaliDate";
import {utils} from "../helper/utils";

class Home extends Component {

    getNepaliDate = () => {
        let nepaliDate;
        try {
            var dateObj = new Date();
            var month = dateObj.getMonth() + 1;
            var day = dateObj.getDate();
            var year = dateObj.getFullYear();
            var hour = dateObj.getHours();
            var minute = dateObj.getMinutes();
            var second = dateObj.getSeconds();
            nepaliDate = getBsDateByAdDate(year, month, day);
            return {
                date: utils.format('{} - {} - {}', nepaliDate['bsYear'], nepaliDate['bsMonth'], nepaliDate['bsDate']),
                time: utils.format('{} - {} - {}', hour, minute, second)
            };
        } catch (e) {
            console.log('getBsDateByAdDate error=', e.getMessage);
            return {};
        }
    };

    constructor(props) {
        super(props);

        // this tell weather it is necessary to re-create entire dom tree
        this.updateKey = +new Date();

        this.state = {
            preview: false,
            patientInfo: {...this.getNepaliDate()},
            patientReport: {},
            noneEmptyCheck: {}
        };
    }

    componentDidMount() {
        console.log('Home componentDidMount()');
        this.props.getDataPlaceholder();
    }

    handleSaveToPC = jsonData => {
        const fileData = JSON.stringify(jsonData);
        const blob = new Blob([fileData], {type: "text/plain"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = utils.format('{}_{}_{}.json',
            this.state.patientInfo.name ? this.state.patientInfo.name.replace(/ /g, '-') : 'Unknown',
            this.state.patientInfo.date.replace(/ /g, ''),
            this.state.patientInfo.time.replace(/ /g, '')
        );
        link.href = url;
        link.click();
    };

    handleLoadFromPC = () => {
        var inputTag = document.createElement("input");
        inputTag.type = "file";
        inputTag.accept = ".json";
        inputTag.multiple = false;
        inputTag.onchange = this.loadData;
        inputTag.click();
    };

    onPatientReportChanged = (reportName, result) => {
        this.setState({
            patientReport: {
                ...this.state.patientReport,
                [reportName]: {...this.state.patientReport[reportName], ...result}
            }
        });
        console.log('onPatientReportChanged=', this.state);
    };

    onPatientInfoChanged = (testId, testValue) => {
        this.setState({
            patientInfo: {
                ...this.state.patientInfo,
                [testId]: testValue
            }
        });
        console.log('onPatientReportChanged=', this.state);
    };

    onClear = (reportName) => {
        this.updateKey = +new Date();
        this.setState(prevState => {
            return {
                patientReport: {
                    ...prevState.patientReport,
                    [reportName]: {}
                },
                noneEmptyCheck: {
                    ...prevState.noneEmptyCheck, [reportName]: false
                }
            }
        });
    };

    saveData = () => {
        this.handleSaveToPC(this.state);
    };

    loadData = (e) => {
        var reader = new FileReader();
        reader.onload = this.onReaderLoad;
        reader.readAsText(e.target.files[0]);
    };


    onReaderLoad = (event) => {
        console.log('onReaderLoad=', event.target.result);

        try {
            var data = JSON.parse(event.target.result);
            console.log('data=', data);
            this.updateKey = +new Date();
            this.setState({...data});
            console.log("forced updated");
        } catch (e) {
            console.log('loadData json exception=', e.getMessage);
        }
    };

    removeData = () => {
        const result = window.confirm("All data will be lost. Do you want to continue?");
        if (result) {
            window.location.reload();
            return true;
        } else {
            return false;
        }
    };

    showPreview = () => {
        let atLeastOneReport = false;
        for (const [, value] of Object.entries(this.state.noneEmptyCheck)) {
            if (value) {
                atLeastOneReport = true;
                break;
            }
        }

        if (!this.state.preview && !atLeastOneReport) {
            alert("There must be at least one report.")
        } else {
            this.setState(prevState => {
                return {preview: !prevState.preview}
            });
        }
    };

    noneEmptyCheck = (id, isEmpty) => {
        this.setState(prevState => {
            return {noneEmptyCheck: {...prevState.noneEmptyCheck, [id]: isEmpty}}
        });
    };

    printPage = () => {
        window.print();
    };

    render() {
        const {loading, dataPlaceholder, error} = this.props.dataReducer;
        const {preview, patientInfo, patientReport, noneEmptyCheck} = this.state;
        const {gender} = patientInfo;

        console.log("loading=", loading);
        console.log("placeholder=", dataPlaceholder);
        console.log("error=", error);
        console.log("patientInfo=", patientInfo);
        console.log("patientReport=", patientReport);
        console.log("noneEmptyCheck=", noneEmptyCheck);
        console.log("gender=", gender);

        return (error ? <div>
                    <h1 className='error'>There is an error. Please check logs</h1>
                </div> :
                <div className={preview ? 'preview' : ''} key={this.updateKey}>
                    <nav id="side_nav_bar" className="navbar sticky-top navbar-dark bg-dark">
                        <div>
                            <h2 className='text-light m-2 align-self-center'>
                                <img id='jagdamba-logo'
                                     className='align-self-center'
                                     src={process.env.PUBLIC_URL + '/microscope@192x192.png'}
                                     alt=''/>JAGDAMBA LAB</h2>
                        </div>
                        <div>
                            {!preview &&
                            <button type="button" onClick={this.saveData}
                                    className="btn align-self-center btn-lg btn-success m-2">Save
                            </button>}

                            {!preview &&
                            <button type="button" className="btn btn-lg btn-primary m-2"
                                    onClick={this.handleLoadFromPC}>Load
                            </button>}

                            {preview && <button type="button"
                                                onClick={this.printPage}
                                                className="btn btn-lg btn-info m-2">Print
                            </button>}
                        </div>
                        <button type="button"
                                className={`btn btn-lg ${preview ? 'btn-success' : 'btn-warning'} m-2`}
                                onClick={this.showPreview}>{preview ? "Edit" : "Preview"}
                        </button>
                        <button type="button" onClick={this.removeData}
                                className="btn btn-lg btn-outline-danger">Clear
                        </button>
                    </nav>

                    <div id={`${preview ? '' : 'wrapper'}`} className="toggled">
                        {!preview && <div id="sidebar-wrapper">
                            <ul className="sidebar-nav">
                                <li><a href="#PATIENT_INFO">PATIENT INFORMATION</a></li>
                                {dataPlaceholder && Object.keys(dataPlaceholder).map(key => {
                                    return <li key={key}>
                                        <a className={`${noneEmptyCheck[key] ? 'override-sidebar-nav-li-a' : ''}`}
                                           href={`#${key}`}>{dataPlaceholder[key]['name']}</a>
                                    </li>
                                })}
                            </ul>
                        </div>}
                        <div id="page-content-wrapper">
                            {dataPlaceholder && <div className='container-fluid'>
                                <PatientInfo id='PATIENT_INFO'
                                             onPatientInfoChanged={this.onPatientInfoChanged}
                                             preview={preview}
                                             patientInfo={patientInfo}
                                />
                                {Object.keys(dataPlaceholder).map(key => {
                                    switch (key) {
                                        case 'WIDAL':
                                            return <WidalSection key={key}
                                                                 id={key}
                                                                 onPatientReportChanged={this.onPatientReportChanged}
                                                                 onClear={this.onClear}
                                                                 preview={preview}
                                                                 extras={{gender}}
                                                                 noneEmptyCheck={this.noneEmptyCheck}
                                                                 patientReport={patientReport[key]}
                                                                 placeholder={dataPlaceholder[key]}/>;
                                        default:
                                            return <Section key={key}
                                                            id={key}
                                                            onPatientReportChanged={this.onPatientReportChanged}
                                                            onClear={this.onClear}
                                                            preview={preview}
                                                            extras={gender ? {gender} : {gender: ''}}
                                                            noneEmptyCheck={this.noneEmptyCheck}
                                                            patientReport={patientReport[key]}
                                                            placeholder={dataPlaceholder[key]}/>;
                                    }
                                })}
                            </div>}
                        </div>
                    </div>
                </div>
        )
    }
}


const mapState = (state) => {
    return {
        dataReducer: state.dataReducer,
    };
};

const actionCreators = {
    getDataPlaceholder: dataActions.getDataPlaceholder,
};

export default connect(mapState, actionCreators)(Home);
