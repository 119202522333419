import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import store from './helper/store'
import {Route, Router, Switch} from "react-router";
import {history} from "./helper/history";
import 'bootstrap/dist/css/bootstrap.min.css';

serviceWorker.register();

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <Switch>
                <Route path="/" component={App}/>
            </Switch>
        </Router>
    </Provider>,
    document.getElementById('root')
);
