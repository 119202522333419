import React, {Component} from 'react';

class TextAreaX extends Component {
    constructor(props) {
        super(props);

        this.ref = React.createRef();

        let allSuggestion = localStorage.getItem('suggestions');

        if (allSuggestion) {
            try {
                allSuggestion = JSON.parse(allSuggestion);
            } catch (e) {
                allSuggestion = {};
            }
        } else {
            allSuggestion = {};
        }

        this.state = {
            focused: false,
            suggestions: allSuggestion[this.props.name] ? allSuggestion[this.props.name] : [],
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.ref.current.style.height = "0px";
        const scrollHeight = this.ref.current.scrollHeight;
        this.ref.current.style.height = scrollHeight + "px";
    }

    onChange = (e) => {
        const {value} = e.target;
        this.props.onChange(value);
    };

    onFocus = (e) => {
        console.log('onFocus');
        this.setState({focused: true});
    };

    onItemClicked = (value) => {
        console.log("onItemClicked");
        this.setState({focused: false});
        this.props.onChange(value);
    };

    onBlur = () => {
        console.log("onOutsideClick");

        let {suggestions} = this.state;
        const value = this.props.defaultValue;
        const numHistory = this.props.numHistory;

        if (value && !suggestions.includes(value)) {
            suggestions.splice(0, 0, value);
            suggestions = suggestions.slice(0, numHistory);
            this.setState({focused: false, suggestions});

            this.storeSuggestions(suggestions);
        } else {
            this.setState({focused: false});
        }
    };

    storeSuggestions = (suggestions) => {
        const {name} = this.props;

        let allSuggestions = localStorage.getItem("suggestions");
        if (allSuggestions) {
            try {
                allSuggestions = JSON.parse(allSuggestions);
            } catch (e) {
                allSuggestions = {};
            }
            allSuggestions = {...allSuggestions, [name]: suggestions}
        } else {
            allSuggestions = {[name]: suggestions}
        }

        localStorage.setItem("suggestions", JSON.stringify(allSuggestions));
    };

    clearHistory = (e, i) => {
        console.log('clearHistory');

        e.stopPropagation();
        const {suggestions} = this.state;
        suggestions.splice(i, 1);
        this.setState({suggestions});

        this.storeSuggestions(suggestions);
    };

    render() {
        const {focused, suggestions} = this.state;
        const {className, name, showSuggestion} = this.props;
        const value = this.props.defaultValue;

        const filtered_suggestion = suggestions.filter(
            suggestion => !value || suggestion.toLowerCase().includes(value.toLowerCase())
        );

        console.log('TextAreaX render()=', this.state);

        return (
            <div className='dropdown'>
                <textarea
                    className={className}
                    name={name}
                    value={value}
                    ref={this.ref}
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    onBlur={filtered_suggestion.length === 0 ? this.onBlur : null}
                    rows='1'
                />

                {showSuggestion && focused && filtered_suggestion.length > 0 &&
                <OutsideAlerter onOutsideClick={this.onBlur}>
                    <div className='dropdown-menu show'>
                        {filtered_suggestion.map((suggestion, i) => {
                            return <div key={i} onClick={() => {
                                this.onItemClicked(suggestion);
                            }} className="dropdown-item show">
                                {suggestion}
                                <button
                                    className='float-right center-vertical btn-sm btn-outline-dark btn-no-bg-no-outline'
                                    onClick={(e) => {
                                        this.clearHistory(e, i);
                                    }}>x
                                </button>
                            </div>
                        })}
                    </div>
                </OutsideAlerter>}
            </div>
        );
    }
}

class OutsideAlerter extends Component {
    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.props.onOutsideClick) {
                this.props.onOutsideClick();
            }
        }
    }

    render() {
        return <div ref={this.setWrapperRef}>{this.props.children}</div>;
    }
}

export default TextAreaX;
