import React, {Component} from 'react';
import TextAreaX from "./TextAreaX";

class PatientInfo extends Component {

    onChange = (e) => {
        const {name, value} = e.target;
        console.log('onChange');
        this.props.onPatientInfoChanged(name, value);
    };


    onSuggestionClicked(name, suggestion) {
        console.log('onSuggestionClicked=', name, suggestion);
        this.props.onPatientInfoChanged(name, suggestion);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.patientInfo !== nextProps.patientInfo
            || this.props.placeholder !== nextProps.placeholder
            || this.props.preview !== nextProps.preview;
    }

    render() {
        const {name, age, gender, address, labNumber, refDoctorName, date} = this.props.patientInfo;
        const preview = this.props.preview;

        console.log('render() patientInfo=', this.props.patientInfo);

        return (
            <div className='no-page-break'>
                <span id={this.props.id} className='anchor'/>
                <table className='table table-bordered'>
                    <tbody>
                    <tr>
                        <td width="20%"><b>Patient Name:</b></td>
                        <td width="39%">
                            <TextAreaX
                                onChange={value => {
                                    this.props.onPatientInfoChanged('name', value);
                                }}
                                name='name'
                                preview={preview}
                                showSuggestion={false}
                                numHistory={5}
                                defaultValue={name}/>
                        </td>
                        <td width="10%"><b>Age:</b></td>
                        <td width="16%">
                            <input onChange={this.onChange} type='text' name='age'
                                   defaultValue={age}/>
                        </td>
                        <td width="5%"><b>Sex:</b></td>
                        <td width="10%">
                            <select title="gender"
                                    onChange={this.onChange}
                                    name='gender' value={gender}
                                    tabIndex="1">
                                <option value="Not selected">-</option>
                                <option value="M">M</option>
                                <option value="F">F</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td><b>Address:</b></td>
                        <td>
                            <input onChange={this.onChange} type='text' name='address'
                                   defaultValue={address}/>
                        </td>
                        <td><b>Lab No:</b></td>
                        <td colSpan='3'>
                            <input onChange={this.onChange} type='text'
                                   name='labNumber'
                                   defaultValue={labNumber}/>
                        </td>
                    </tr>
                    <tr>
                        <td><b>Ref. by Dr.</b></td>
                        <td>
                            <TextAreaX onChange={value => {
                                this.props.onPatientInfoChanged('refDoctorName', value);
                            }}
                                       name='refDoctorName'
                                       showSuggestion={true}
                                       numHistory={5}
                                       preview={preview}
                                       defaultValue={refDoctorName}/>
                        </td>
                        <td><b>Date:</b></td>
                        <td colSpan='3'>
                            <input onChange={this.onChange} type='text' name='date'
                                   value={date}/>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default PatientInfo;
