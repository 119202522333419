import React, {Component} from 'react';
import './App.css'
import {utils} from "../helper/utils";

class Section extends Component {

    constructor(props) {
        super(props);

        this.all_empty = true;

    }

    unitRangeCheck = (value, state, preview) => {
        let noUnit = true;
        let noRange = true;
        for (const row of value['data']) {
            if (row['unit'] && (!preview || (preview && state[row['id']] && state[row['id']] !== 'Not selected'))) {
                noUnit = false;
                break;
            }
        }
        for (const row of value['data']) {
            if (row['range'] && (!preview || (preview && state[row['id']] && state[row['id']] !== 'Not selected'))) {
                noRange = false;
                break;
            }
        }
        return {noUnit, noRange};
    };

    unitAndRangeWrapper = (placeholder, state = {}, preview = false) => {
        if (placeholder['sub_category']) {
            let noUnit = true;
            let noRange = true;
            for (const sub_category of placeholder['sub_category']) {
                const result = this.unitRangeCheck(sub_category, state, preview);
                noUnit = noUnit && result.noUnit;
                noRange = noRange && result.noRange;
            }
            return {noUnit, noRange};
        } else {
            const {noUnit, noRange} = this.unitRangeCheck(placeholder, state, preview);
            return {noUnit, noRange};
        }
    };

    onChange = (e) => {
        const {name, value} = e.target;
        // console.log("onChange=", {name, value});
        this.props.onPatientReportChanged(this.props.id, {[name]: value});
    };

    getRange = (item) => {
        switch (item.type) {
            case 'range':
                const range = item.range;
                if (range['M'] && range['F']) {
                    if (range['M']['lower'] && range['F']['lower']) {
                        return utils.format(
                            'M({}-{}) F({}-{})',
                            range['M']['lower'], range['M']['higher'],
                            range['F']['lower'], range['F']['higher']);
                    } else {
                        return utils.format(
                            'M(<{}) F(<{})',
                            range['M']['higher'],
                            range['F']['higher']);
                    }
                } else {
                    if (range['lower']) {
                        return utils.format(
                            '({}-{})',
                            range['lower'],
                            range['higher']);
                    } else {
                        return utils.format(
                            '< {}',
                            range['higher']);
                    }
                }
            default:
                return '';
        }

    };

    getUnit = (item) => {
        const unit = item.unit;
        return unit ? unit : '';
    };

    getInput = (state, item, extras) => {
        let {id, type, range, values, auto_fill} = item;
        switch (type) {
            case 'range': {
                const value = state[id];
                const {gender} = extras;

                if (range['M'] && range['F']) {
                    console.log('gender=', gender);
                    switch (gender) {
                        case 'M':
                            range = range['M'];
                            break;
                        case 'F':
                            range = range['F'];
                            break;
                        default:
                    }
                }

                let isInRange;
                if (range.lower && range.higher) {
                    isInRange = parseFloat(value) >= parseFloat(range.lower) && parseFloat(value) <= parseFloat(range.higher);
                } else if (range.higher) {
                    isInRange = parseFloat(value) < parseFloat(range.higher);
                } else {
                    isInRange = true;
                }

                if (auto_fill) {
                    return <label
                        className={!isInRange ? 'w-100 font-weight-bold' : 'w-100'}>{value}
                    </label>
                }

                return <input name={id}
                              type='number'
                              onChange={this.onChange}
                              className={!isInRange ? 'font-weight-bold' : ''}
                              defaultValue={value}
                />;
            }
            case 'free': {
                const value = state[id];

                if (auto_fill) {
                    return <label
                        className={'w-100'}>{value}
                    </label>
                }

                return <input
                    name={id}
                    type='text'
                    onChange={this.onChange}
                    defaultValue={value}
                />;
            }
            case 'radio': {
                const value = state[id];
                return <select name={id}
                               onChange={this.onChange}
                               value={value}>
                    {values.map((value, i) => {
                        return <option key={i} value={value}>{value}</option>
                    })}
                </select>;
            }
            default:
                return <div/>;

        }
    };

    evaluateExp = (expression, state) => {
        console.log('expression before=', expression);

        const requiredIdsPlaceholders = expression.match(/{\d+}/g);
        const requiredIds = [];
        requiredIdsPlaceholders.forEach(requiredIdsPlaceholder => {
            const requiredId = requiredIdsPlaceholder.substr(1, requiredIdsPlaceholder.length - 2);
            requiredIds.push(requiredId);
        });

        let replacementExpression = expression;
        for (const requiredId of requiredIds) {
            if (state[requiredId]) {
                replacementExpression = replacementExpression
                    .replace(new RegExp('\\{' + requiredId + '\\}', 'g'), state[requiredId])
            } else {
                return '';
            }
        }
        console.log('expression after=', replacementExpression);
        /* eslint-disable-next-line no-eval */
        return Math.round(eval(replacementExpression) * 100) / 100;
    };

    getRow = (state, head, data, extras, noRange, noUnit) => {
        const preview = this.props.preview;
        let all_empty = true;
        for (let item of data) {
            if (!preview || (state[item.id] && state[item.id] !== 'Not selected')) {
                all_empty = false;
                break;
            }
        }

        return <tbody key={head}>
        {head && <tr hidden={all_empty}>
            <th colSpan='4' scope='colgroup'>{head}</th>
        </tr>}
        {data && data.map(item => {
            const hidden = preview && (!state[item.id] || state[item.id] === 'Not selected');
            return <tr hidden={hidden} key={item.id}>
                <td>{item.name}</td>
                <td>{this.getInput(state, item, extras)}</td>
                {!noUnit &&
                <td>{this.getUnit(item)}</td>}
                {!noRange && <td>{this.getRange(item)}</td>}
            </tr>;
        })}
        </tbody>;
    };

    getUpdatedState = (data, state) => {
        const result = {...state};
        console.log('data = ', data);
        let sortedData = data.filter(data => data['auto_fill']);
        sortedData.sort((a, b) => {
            if (b['auto_fill'].includes('{' + a['id'] + '}')) {
                return -1;
            } else if (a['auto_fill'].includes('{' + b['id'] + '}')) {
                return 1;
            } else {
                return 0;
            }
        });
        console.log('sortedData = ', sortedData);
        sortedData.forEach(data => {
            let {id, name, auto_fill} = data;
            try {
                const value = this.evaluateExp(auto_fill, result);
                console.log('evaluateExp for ' + name + ' result=', value);
                result[id] = value;
            } catch (e) {
                console.log('evaluateExp for ' + name + ' error=', e.getMessage);
                result[id] = '';
            }
        });
        return result;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.noneEmptyCheck) {
            this.props.noneEmptyCheck(this.props.id, !this.all_empty);
        }

        let {sub_category, data} = this.props.placeholder;
        let state = this.props.patientReport;

        let result = {};
        if (sub_category) {
            let all_data = [];
            sub_category.forEach(sub_category => {
                all_data = all_data.concat(sub_category.data)
            });
            result = this.getUpdatedState(all_data, state);
        } else if (data) {
            result = this.getUpdatedState(data, state);
        }

        if (Object.keys(result).length > 0) {
            console.log('auto result = ', result);
            this.props.onPatientReportChanged(this.props.id, result);
        } else {
            console.log('auto filled = empty');
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        // console.log('shouldComponentUpdate=', JSON.stringify(this.props.patientReport) !== JSON.stringify(nextProps.patientReport));
        // console.log('current report=', this.props.patientReport);
        // console.log('next report=', nextProps.patientReport);
        const shouldComponentUpdate = JSON.stringify(this.props.patientReport) !== JSON.stringify(nextProps.patientReport)
            || this.props.placeholder !== nextProps.placeholder
            || this.props.preview !== nextProps.preview
            || JSON.stringify(this.props.extras) !== JSON.stringify(nextProps.extras);
        if (shouldComponentUpdate) {
            console.log('updating component ', this.props.placeholder.name);
        }
        return shouldComponentUpdate;
    }

    onClear = () => {
        this.props.onClear(this.props.id);
    };

    render() {
        let {name, sub_category, data} = this.props.placeholder;
        const preview = this.props.preview;
        const extras = this.props.extras;
        let state = this.props.patientReport;

        if (!state) {
            state = {}
        }

        const {noRange, noUnit} = this.unitAndRangeWrapper(this.props.placeholder, state, preview);

        console.log('section render() name, state=', {name, state});

        this.all_empty = true;
        for (const [, value] of Object.entries(state)) {
            if (value && value !== 'Not selected') {
                this.all_empty = false;
                break;
            }
        }

        return (
            <div hidden={preview && this.all_empty} className='no-page-break'>
                <span id={this.props.id} className='anchor'/>
                <h4 className='d-inline-block'>{name}</h4>
                {!preview && <button title="Clear this report" className='float-right btn btn-sm btn-danger'
                                     onClick={this.onClear}>X</button>}
                <div>
                    <table className='table table-bordered'>
                        <thead className={preview ? 'thead-light' : 'thead-dark'}>
                        <tr>
                            <th>Test Name</th>
                            <th>Result</th>
                            {!noUnit && <th>Unit</th>}
                            {!noRange && <th>Ref Range</th>}
                        </tr>
                        </thead>
                        {sub_category ?
                            sub_category.map((sub_category) => {
                                return this.getRow(state, sub_category.name, sub_category.data, extras, noRange, noUnit)
                            })
                            :
                            this.getRow(state, null, data, extras, noRange, noUnit)
                        }
                    </table>
                </div>
            </div>
        )
    }
}


export default Section;
