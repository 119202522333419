import React, {Component} from 'react';

class WidalSection extends Component {

    onChange = (e) => {
        const {name, value} = e.target;
        const {id, column_index} = JSON.parse(name);

        let state = this.props.patientReport;

        if (!state) {
            state = {}
        }

        state = {
            ...state,
            [id]: {
                ...state[id],
                [column_index]: value
            }
        };

        console.log('state=', state);

        this.props.onPatientReportChanged(
            this.props.id,
            {[id]: state[id]}
        );
    };

    getInput = (state, data, findings_column) => {
        const {id, type, values} = data;
        switch (type) {
            case 'free': {
                const value = state[id];
                return findings_column.map((findings_column, i) => {
                    return <td key={i}>
                        <input
                            onChange={this.onChange}
                            name={JSON.stringify({id, column_index: i})}
                            defaultValue={value ? value[i] : ''}
                            type='text'/>
                    </td>
                });
            }
            case 'radio': {
                const value = state[id];
                return findings_column.map((findings_column, i) => {
                    return <td key={i}>
                        <select
                            onChange={this.onChange}
                            name={JSON.stringify({id, column_index: i})}
                            value={value ? value[i] : ''}>
                            {values.map((value, i) => {
                                return <option key={i} value={value}>{value}</option>
                            })}
                        </select>
                    </td>
                });
            }
            default:
                return <td/>
        }
    };

    getRow = (state, findings_column, data, preview) => {
        return <tbody>
        {data.map(data => {
            let all_column_empty = true;
            if (state[data.id]) {
                for (const [, value] of Object.entries(state[data.id])) {
                    if (value && value !== 'Not selected') {
                        all_column_empty = false;
                        break;
                    }
                }
            }
            return <tr hidden={this.props.preview && all_column_empty} key={data.id}>
                <td>{data.name}</td>
                {this.getInput(state, data, findings_column)}
            </tr>
        })}
        </tbody>;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.noneEmptyCheck) {
            this.props.noneEmptyCheck(this.props.id, !this.all_empty);
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.patientReport !== nextProps.patientReport
            || this.props.placeholder !== nextProps.placeholder
            || this.props.preview !== nextProps.preview
            || JSON.stringify(this.props.extras) !== JSON.stringify(nextProps.extras);
    }

    onClear = () => {
        this.props.onClear(this.props.id);
    };

    render() {
        const {name, findings_column, data} = this.props.placeholder;
        const preview = this.props.preview;

        let state = this.props.patientReport;

        if (!state) {
            state = {}
        }

        console.log('widal section section render() state=', state);

        this.all_empty = true;
        for (const [, value] of Object.entries(state)) {
            for (const [, v] of Object.entries(value)) {
                if (v && v !== 'Not selected') {
                    this.all_empty = false;
                    break;
                }
            }
            if (!this.all_empty) {
                break;
            }
        }

        return (
            <div hidden={preview && this.all_empty} className='no-page-break'>
                <span id={this.props.id} className='anchor'/>
                <h4 className='d-inline-block'>{name}</h4>
                {!preview && <button title="Clear this report" className='float-right btn btn-sm btn-danger'
                                     onClick={this.onClear}>X</button>}
                <table className='table table-bordered'>
                    <thead className={preview ? 'thead-light' : 'thead-dark'}>
                    <tr>
                        <th rowSpan='1'>Test name</th>
                        <th colSpan='5'>Result</th>
                    </tr>
                    </thead>
                    <thead className='thead-light'>
                    <tr>
                        <th rowSpan='2'>Antigen</th>
                        <th colSpan='5'>Dilution</th>
                    </tr>
                    <tr>
                        {findings_column.map((findings_column, i) => {
                            return <th key={i}>{findings_column}</th>
                        })}
                    </tr>
                    </thead>
                    {this.getRow(state, findings_column, data, preview)}
                </table>
            </div>
        )
    }
}

export default WidalSection;
