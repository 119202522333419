import {apiService} from "../services/apiService";
import {dataConstants} from "../constants/dataConstants";

function getDataPlaceholder() {
    return dispatch => {
        dispatch(request());

        apiService.getDataPlaceholder()
            .then(response => {
                dispatch(success(response.data));
                console.log('response=', response.data);
            })
            .catch(error => {
                console.log('error=', error);
                dispatch(failure(error));
            });
    };

    function request() {
        return {
            type: dataConstants.DATA_REQUEST,
        };
    }

    function success(dataPlaceholder) {
        return {
            type: dataConstants.DATA_SUCCESS,
            dataPlaceholder
        };
    }

    function failure(error) {
        console.log(error);
        return {
            type: dataConstants.DATA_FAILURE,
        };
    }
}


export const dataActions = {getDataPlaceholder};
