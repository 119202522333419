import {dataConstants} from "../constants/dataConstants";

const initialState = {};

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case dataConstants.DATA_REQUEST:
            return {
                loading: true
            };
        case dataConstants.DATA_SUCCESS:
            return {
                dataPlaceholder: action.dataPlaceholder
            };
        case dataConstants.DATA_FAILURE:
            return {
                error: true
            };
        default:
            return state;
    }
};

export {dataReducer};
