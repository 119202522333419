import axios from 'axios'

function getDataPlaceholder() {
    const request = axios.get(process.env.PUBLIC_URL + '/assets/dataPlaceholder.json');

    console.log(request);

    return request;
}

export const apiService = {
    getDataPlaceholder
};
